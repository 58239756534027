import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import WidgetLoader from "../components/widgets/WidgetLoader"

const HomePage = ({ data: { sanityStartPage }, pageContext }) => {
  const { widgets } = sanityStartPage
  const { loginEnabled } = pageContext.sanityLoginPage
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  return (
    <LayoutComponent
      meta={{ title: "C'est Normal" }}
      pageContext={pageContext}
      absoluteHeader
    >
      {widgets?.length && <WidgetLoader widgets={widgets} />}
    </LayoutComponent>
  )
}

export default HomePage

export const query = graphql`
  query {
    sanityStartPage {
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
        ...Image
      }
    }
    sanityLoginPage {
      loginEnabled
    }
  }
`
